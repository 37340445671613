<template>
    <Submenu
        :links="[
            { name: 'nouns.neuterNouns.header', icon: 'deer', route: 'neutratywy' },
            { name: 'nouns.dukajNouns.header', icon: 'ghost', route: 'dukatywy' },
            { name: 'nouns.personNouns.header', icon: 'user-friends', route: 'osobatywy' },
            { name: 'nouns.xNouns.header', icon: 'comment-times', route: 'iksatywy' },
        ]"
    />
</template>
